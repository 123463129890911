// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import Home from './Home';
import Search from './Search'; // Make sure Search component is ready for props
import AddRecipe from './AddRecipe';
import AllRecipes from './AllRecipes';
import Cuisines from './Cuisines';
import RecipeDetails from './RecipeDetails';
import './App.css';

function App() {
  const [recipes, setRecipes] = useState([]);

  useEffect(() => {
    fetch('/recipe_DB_v1.json')
      .then(response => response.json())
      .then(data => setRecipes(Object.values(data)));
  }, []);

  return (
    <Router>
      <div className="App">
        <header>
          <h1 className="title">Matthew's Recipe Database</h1>
          <nav className="navBar">
            <Link to="/">Home</Link>
            <Link to="/all-recipes">All Recipes</Link>
            <Link to="/Cuisines">Cuisines</Link>
            <Link to="/add-recipe">Add Recipe</Link>
            <Link to="/search">Search</Link>
          </nav>
        </header>
        <Routes>
          <Route exact path="/" element={<Home recipes={recipes} />} />
          <Route exact path="/home" element={<Home recipes={recipes} />} />
          <Route path="/search" element={<Search recipes={recipes} />} /> {/* Pass recipes to Search */}
          <Route path="/add-recipe" element={<AddRecipe />} />
          <Route path="/Cuisines" element={<Cuisines />} />
          <Route path="/all-recipes" element={<AllRecipes />} />
          <Route path="/recipes/:id" element={<RecipeDetails recipes={recipes} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
