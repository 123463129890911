import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Home.module.css'; // Import CSS module

function Home({ recipes }) {
  return (
    <div className={styles.container}>
      <h2>Recipes List</h2>
      <div className={styles.recipeList}>
        {recipes.map(recipe => (
          <Link key={recipe.ID} to={`/recipes/${recipe.ID}`}>
            <button className={styles.recipeButton}>{recipe.Name}</button>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Home;
