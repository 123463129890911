import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styles from './RecipeDetails.module.css'; // Import CSS module

function RecipeDetails({ recipes }) {
  const { id } = useParams();
  const recipe = recipes.find(r => r.ID === id);

  if (!recipe) {
    return <h2 className={styles.notFound}>Recipe Not Found</h2>;
  }  
    
  // Calculate total time
  const calculateTotalTime = (instructions, type) => {
    return instructions
      .filter(instruction => instruction.Type === type)
      .reduce((total, instruction) => total + parseInt(instruction.Time, 10), 0);
  };

  // Format time in minutes and seconds
  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes} minutes${seconds > 0 ? ` ${seconds} seconds` : ''}`;
  };

  if (!recipe) {
    return <h2 className={styles.notFound}>Recipe Not Found</h2>;
  }

  const prepTime = calculateTotalTime(Object.values(recipe.Instructions), 'prep');
  const cookTime = calculateTotalTime(Object.values(recipe.Instructions), 'cook');

  const ingredientsList = recipe?.Ingredients
    ? Object.entries(recipe.Ingredients).map(([key, value]) => (
        <li key={key}>{value.Amount} {value.Units} of {value.Name}</li>
      ))
    : null;

  const instructionsList = recipe?.Instructions
    ? Object.values(recipe.Instructions).map((instruction, index) => (
        <li key={index}>{index + 1}. {instruction.Name}</li>
        ))
    : null;


  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{recipe.Name}</h1>
      <div className={styles.recipeLayout}>
        <div className={styles.details}>
          <p className={styles.detailItem}><strong>Author:</strong> {recipe.Author}</p>
          <p className={styles.detailItem}><strong>Cuisine:</strong> {recipe.Cuisine}</p>
          <p className={styles.detailItem}><strong>Prep Time:</strong> {formatTime(prepTime)}</p>
          <p className={styles.detailItem}><strong>Cook Time:</strong> {formatTime(cookTime)}</p>
          {/* Additional details here */}
        </div>
        <div className={styles.ingredientsInstructions}>
          <h2>Ingredients</h2>
          <ul>{ingredientsList}</ul>
          <h2>Instructions</h2>
          <ol>{instructionsList}</ol>
        </div>
      </div>
      <Link to="/">Back to Home</Link>
    </div>
  );
}

export default RecipeDetails;
