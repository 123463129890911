import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Search.module.css'; // Importing the CSS module

function Search({ recipes }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchCategory, setSearchCategory] = useState('Any');
  const [searchResults, setSearchResults] = useState([]);

  // Handle category change
  const handleCategoryChange = (e) => {
    setSearchCategory(e.target.value);
  };

  // Handle search term change
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle form submission
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    let results;
    if (searchCategory === 'Any') {
      results = recipes.filter(recipe => 
        Object.values(recipe).join(' ').toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      results = recipes.filter(recipe => 
        recipe[searchCategory]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    setSearchResults(results);
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSearchSubmit}>
        <select className={styles.selectCategory} value={searchCategory} onChange={handleCategoryChange}>
          <option value="Any">Any</option>
          <option value="Author">Author</option>
          <option value="Name">Name</option>
          <option value="Cuisine">Cuisine</option>
          {/* Add other categories as options if needed */}
        </select>
        <input 
          className={styles.searchInput} 
          type="text" 
          value={searchTerm} 
          onChange={handleSearchTermChange} 
          placeholder="Search term"
        />
        <button className={styles.searchButton} type="submit">Search</button>
      </form>

      {searchResults.length > 0 ? (
        <table className={styles.table}>
          <thead>
            <tr className={styles.tr}>
              <th className={styles.th}>Name</th>
              <th className={styles.th}>Author</th>
              <th className={styles.th}>Cuisine</th>
              {/* Add other relevant columns */}
            </tr>
          </thead>
          <tbody>
            {searchResults.map((recipe, index) => (
              <tr className={styles.tr} key={index}>
                <td className={styles.td}>
                  <Link to={`/recipes/${recipe.ID}`}>{recipe.Name}</Link>
                </td>
                <td className={styles.td}>{recipe.Author}</td>
                <td className={styles.td}>{recipe.Cuisine}</td>
                {/* Add other data cells */}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className={styles.noResults}>No results found</div>
      )}
    </div>
  );
}

export default Search;
